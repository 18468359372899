import { Debug } from '@sentry/integrations'

import { CarrierDto } from '../../../../api/carrier/carrier-client'
import { GetCarrierList } from '../../../../api/carrier/carrierApi'
import {
  EligibilityPatient,
  PatientEligibilityForm,
} from '../../../../api/eligibility/eligibility-client/api'
import {
  Get271Response,
  GetPatient,
} from '../../../../api/eligibility/eligibilityApi'
import { NotifyText } from '../../../../constants/notifyText'
import { IAuthenticatedApiModel } from '../../../../utilities/api'
import { EligibilityResponseProps } from '../EligibilityResponse'

interface EligibilityResponseCarrierInfo {
  carrierFax: string | null
  carrierId: string | null
  carrierName: string | null
  carrierPhone: string | null
  carrierWebsite: string | null
  essentialViewSupported: boolean | null
}

interface EligibilityData {
  CarrierId?: string
  CarrierName?: string
  HistoryId?: number
  PatientBirthdate?: string
  PatientFirstName?: string
  PatientId?: string
  PatientLastName?: string
  Status?: string
}

export const getEligibilityResponse = async <
  IncomingDataType extends EligibilityData,
>(
  authentication: IAuthenticatedApiModel,
  incomingData: IncomingDataType,
  verificationResponse: string = undefined,
  verificationResponseId: number = undefined,
): Promise<EligibilityResponseProps> => {
  let htmlResult: string = ''
  let carrier: EligibilityResponseCarrierInfo | null = null
  let patient: EligibilityPatient | null = null
  let patResponse: PatientEligibilityForm | null = null
  let unableToSendForm: boolean
  let sViewEnabled: boolean = false

  if (verificationResponse) htmlResult = verificationResponse
  else {
    try {
      const { data } = await Get271Response(
        incomingData.HistoryId ?? verificationResponseId,
      )
      if (data.Response_271_HTML) htmlResult = data.Response_271_HTML
      if (data.CarrierId) {
        const carrierMatch = await getCarrier(data.CarrierId)
        if (carrierMatch) carrier = transformCarrierInfo(carrierMatch)
      }
      if (data.PatientFormResponse) patResponse = data.PatientFormResponse
      if (carrier?.essentialViewSupported)
        sViewEnabled = carrier.essentialViewSupported
      unableToSendForm = data.UnableToSendForm
    } catch (error) {
      throw new Error(NotifyText.getEligibilityResponseError)
    }
  }

  if (incomingData.PatientId && !incomingData.PatientBirthdate)
    patient = await getPatient(incomingData)
  else
    patient = {
      PatientBirthdate: incomingData.PatientBirthdate,
      PatientFirstName: incomingData.PatientFirstName,
      PatientLastName: incomingData.PatientLastName,
    }

  if (!carrier && (incomingData.CarrierId || incomingData.CarrierName)) {
    const carrierMatch = await getCarrier(
      incomingData.CarrierId,
      incomingData.CarrierName,
    )
    if (carrierMatch) carrier = transformCarrierInfo(carrierMatch)

    if (carrier?.essentialViewSupported)
      sViewEnabled = carrier.essentialViewSupported
  }

  return {
    ...carrier,
    patientBirthdate: patient.PatientBirthdate,
    patientName: `${patient.PatientFirstName} ${patient.PatientLastName}`,
    patientStatus: incomingData.Status,
    verificationResponse: htmlResult,
    patientResponse: patResponse,
    historyId:
      verificationResponseId !== undefined
        ? verificationResponseId
        : incomingData.HistoryId,
    essentialsViewEnabled: sViewEnabled,
    unableToSendForm: unableToSendForm,
  }
}

const getPatient = async <IncomingDataType extends EligibilityData>(
  incomingData: IncomingDataType,
): Promise<EligibilityPatient> => {
  return await GetPatient(incomingData.PatientId).then(({ data }) => data)
}

const getCarrier = async (
  carrierId: string | null,
  carrierName: string | null = null,
): Promise<CarrierDto> => {
  return await GetCarrierList().then(({ data }) =>
    data.find((carrier: CarrierDto) => {
      if (carrierId && carrier.Id === carrierId) return carrier
      else if (carrierId && carrier.Aliases?.includes(carrierId)) return carrier
      else if (
        carrierName &&
        (carrier.Name?.toLocaleLowerCase() ===
          carrierName.toLocaleLowerCase() ||
          carrier.Aliases?.map((name) => name?.toLocaleLowerCase())?.includes(
            carrierName.toLocaleLowerCase(),
          ))
      ) {
        return carrier
      } else return null
    }),
  )
}

const transformCarrierInfo = (
  carrier: CarrierDto,
): EligibilityResponseCarrierInfo => {
  const {
    Fax,
    Id,
    Name,
    PrimaryPhone,
    ProviderPortalWebsite,
    ProviderRelationsPhone,
    ProviderServicesFax,
    PublicWebsiteUrl,
    EssentialViewSupported,
  } = carrier

  const carrierInfo: EligibilityResponseCarrierInfo = {
    carrierFax: ProviderServicesFax ? ProviderServicesFax : Fax,
    carrierId: Id,
    carrierName: Name,
    carrierPhone: ProviderRelationsPhone
      ? ProviderRelationsPhone
      : PrimaryPhone,
    carrierWebsite: ProviderPortalWebsite
      ? ProviderPortalWebsite
      : PublicWebsiteUrl,
    essentialViewSupported: EssentialViewSupported
      ? EssentialViewSupported
      : false,
  }

  return carrierInfo
}
